.empty{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.text{
    vertical-align: center;
    font-weight: 600;
    color: #d3d3d3;
}

.icon{
    font-size: 2em;
    color: #d3d3d3;
    margin: 100% 1em 0.5em 1em;
}