.dialog{
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 50%;
    max-width: 400px;
    min-height: 300px;
    background-color: #FEFEFE;
    border-radius: 10px;
    boxShadow: 24;
    p: 4;

}

.container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    text-align: center;
}


