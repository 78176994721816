.content{
    display: flex;
    height: 3em;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: center;
    text-align: center;
    border-radius: 5px;
    background-color: #F8F8F8;
    margin: 0.2em 1em;
    padding: 0.5em;
    line-height: 1.5em;
}

.text{
    padding: 0.5em 0;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 0.8em;
    text-align: left;
    width: 80%;
    font-weight: 600;
}

.date{
    font-size: 0.5em;
    color: #d3d3d3;
    width: 20%;
    padding-right: 0.5em;
}
