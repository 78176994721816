.input{
    position: relative;
    align-items: center;
    width: 100%;
    bottom : 1em;
    padding : 0 1em;
}

.inputContent{
    border : 1px solid #d3d3d3;
    border-radius: 15px;
    height: 2em;
    width: 100%;
    font-size: 1em;
    padding-left: 1em;
}

.button{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0.7em;
    top: 0.2em;
    background-color: #34A853;
    width: 1.6em;
    height: 1.6em;
    border-radius: 50%;
    margin: 0 0.5em;
}

.icon{
    color: #FEFEFE;
    font-weight: 800;
}
