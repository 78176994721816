.box{
    background-color: #e8e84b;
    border-radius: 10px 10px 0 0 ;
}

.count{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1em;
    margin-top: 1.5em;
}

.item{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin: 0 0.5em;
    height: 100%;
}

.name{
    display: flex;
    flex-direction: column;
    vertical-align: center;
    font-weight: 600;
    margin: 0 0.5em;
    color: #424242;
}

.number{
    font-size: 1.3em;
    font-weight: 700;
    color: #212121;
}

.loading{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1.5em;
    margin: 1em;
    height: 3em;
    border-radius: 10px;
    background-color: rgba(166, 166, 166, 0.2);
}

.loading::after {
    position: absolute;
    display: block;
    content: "";
    width: 30%;
    height: 3em;
    transform: translateX(-100%);

    background: linear-gradient(90deg, rgba(236, 236, 236, 0.4),
    rgba(255, 255, 255, 0.52), rgba(236, 236, 236, 0.4));
    animation: loading 0.8s infinite;
}

@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}