.listwrapper{
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 400px;
    width: 100%;
    height: 100%;
    background-color: #FEFEFE;
    /*border-radius: 10px;*/
    boxShadow: 24;
    p: 4;
    overflow: auto;
}
