:root {
    --vh: 100%;
}

.container{
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.search{
    max-width: 360px;
    width: 100%;
    height: 2.5em;
    position: fixed;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    margin: 0.5em 0;
    border: 1.5px solid #d3d3d3;
    border-radius: 15px;
    z-index: 100;
}

.map{
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    z-index: 0;
}

.geoLocationIcon{
    position: fixed;
    background-color: #FEFEFE;
    padding: 0.5em;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    z-index: 200;
    top: 4em;
    right: 1em;
    border: 1px solid #d3d3d3;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.096);
    display: flex;
    justify-content: center;
    align-items: center;
}

.questionIcon{
    position: fixed;
    background-color: #FEFEFE;
    padding: 0.5em;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    z-index: 200;
    top: 7em;
    right: 1em;
    border: 1px solid #d3d3d3;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.096);
    display: flex;
    justify-content: center;
    align-items: center;
}


.btn{

}



.searchlist{
    background-color: #FEFEFE;
    width: 100%;
    max-width: 420px;
    height: 100%;
}





.getlocation:hover{
    background-color: #e1e1e1;
}

.icon{
    margin: auto;
    color: #2a2a2a;



}