.menu{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 400px;
    width: 90%;
    height: 50%;
    background-color: #FEFEFE;
    border-radius: 10px;
    boxShadow: 24;
    p: 4;
}

.top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 10%;
}

.tab{
    display: flex;
    text-align: center;
    margin: auto;
    height: 2em;
    vertical-align: middle;
}

.content{
    width: 100%;
    height: 85%;
}

.item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 0em 1em 1em 1em;
    font-size: 1em;
    font-weight: 600;
    color: #b7b7b7;
    padding: 0.2em;
    /*border-bottom: 1px solid #b7b7b7;*/
}

.select{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 0em 1em 1em 1em;
    font-size: 1em;
    font-weight: 600;
    color: #3e6def;
    border-bottom: 2px solid #3e6def;
    padding: 0.2em;
}

.text{
}

.icon{
    width: 1em;
    height: 1em;
    font-size: 1em;
    margin-right: 0.3em;
}

.notice{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: auto;
}

.notice .content{
    text-align: center;
    line-height: 1.5em;
    font-size: 0.8em;
}

.notice .contact{
    margin: 2em 0em;
    text-align: center;
    font-size: 0.8em;
}

