.article{
    border-radius: 10px;
    border: 1px solid #D3D3D3;
    padding: 1em;
    margin: 0.5em;
}

.article:hover{
    background-color: #e1e1e1;
}

.title{
    width: 80%;
    font-weight: 600;
    font-size: 0.9em;
}

.date{
    float: right;
    width: 30%;
    font-size: 0.6em;
    color: #424242;
}