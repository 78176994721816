.board{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1em;
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;


}

.title{
    width: 100%;
    height: 2.5em;
    border: 1px solid #d3d3d3;
    border-bottom: 0px;
    border-radius: 10px 10px 0 0;
    display: flex;

}

.content{
    width: 100%;
    height: 2.5em;
    border: 1px solid #d3d3d3;
    border-radius: 0 0 10px 10px;
    display: flex;
}

.inputtitle{
    width: 100%;
    vertical-align: center;
    font-size: 1em;
    color: #383838;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none; /*remove the resize handle on the bottom right*/
    margin-left: 0.5em;
}

.inputcontent{
    width: 100%;
    vertical-align: center;
    font-size: 1em;
    color: #383838;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none; /*remove the resize handle on the bottom right*/
    margin-left: 0.5em;
}

.desc{
    margin: 0.5em;
    text-align: center;
}

.desctext{
    font-size: 0.7em;
}

.btn{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(238, 238, 238, 0.84);
    height: 2em;
    border-radius: 10px;
    margin-top: 1em;
}

.btn:hover{
    background-color: rgba(238, 238, 238, 0.84);
    color: #212121;
}


.text{
    font-size: 0.9em;
    font-weight: 600;
    color: #424242;
}