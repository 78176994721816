:root {
    --vh: 100%;
}

.frame{
    display: flex;
    align-content: center;
    height: calc(var(--vh, 1vh) * 100);
    font-family: 'Noto Sans KR', normal;


}

.content{
    height: 100%;
    width: 100%;
    margin: auto;
}

.screen-box{
    -ms-overflow-style:none; /* IE and Edge */
    scrollbar-width:none; /* Firefox */
}

.screen-box::-webkit-scrollbar {
    display:none; /* Chrome , Safari , Opera */
}