.article{
    border-radius: 10px;
    border: 1px solid #D3D3D3;
    margin: 1em;
    overflow: auto;
    height: 85%;
}

.article {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.content{
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 1em;
}

.title{
    font-size: 1.1em;
    font-weight: 600;
}

.date{
    font-size: 0.7em;
    color: #424242;
    margin: 1em 0;
}

.contentText{
    line-height: 1.5em;
}

.loading{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(166, 166, 166, 0.2);
}

.loading::after {
    position: absolute;
    display: block;
    content: "";
    width: 30%;
    height: 100%;
    transform: translateX(-100%);

    background: linear-gradient(90deg, rgba(236, 236, 236, 0.4),
    rgba(255, 255, 255, 0.52), rgba(236, 236, 236, 0.4));
    animation: loading 0.8s infinite;
}

@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}