.drawer{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    justify-items: center;
}

.baricon{
    margin : 0.5em;
    font-size: 1.2em;
    color: #d3d3d3;
}

.addBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(225, 225, 225, 0.84);
    height: 2.3em;
    border-radius: 5px;
    margin: 1em;
}

.addBtn:hover{
    background-color: rgba(238, 238, 238, 0.84);
    color: #212121;
}

.btnText{
    font-weight: 700;
    color: #989898;
}

